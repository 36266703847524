import { ChangeDetectorRef, Directive, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AlertType } from '@fgp/shared/interfaces';
import { AdminSessionService } from '@fgp/shared/services/admin-session';
import { SubscribeService } from '@fgp/subscribe/data-access';
import { ISuscripcionContacto } from '@fgp/subscribe/interface';
import { AlertService } from '@fgp/utils/alert';
import { ValidarResponse } from '@fgp/utils/validate-response';
import { validarEspacios } from '@fgp/utils/validate-white-spaces';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalThanksNewsletterComponent } from 'libs/subscribe/ui/modal-thanks-newsletter/src/lib/modal-thanks-newsletter/modal-thanks-newsletter.component';

import { SweetAlertResult } from 'sweetalert2';

const MONTHS = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre'
];

@Directive({
  selector: '[fgpSubscribeBase]'
})
export class SubscribeBaseDirective implements OnInit {
  subscribeForm: UntypedFormGroup;
  submitted = false;
  visibleSubscribe = true;
  @Input() private visibleButtonSubscribe = true;
  hideForm = false;
  modalSuccessRef: NgbModalRef;

  @Input() popUP: boolean;
  @Output() cerrar = new EventEmitter<boolean>();
  @Output() dataSubmitted = new EventEmitter<void>();

  @ViewChild('modalSuccess', { static: false }) modalSuccess: TemplateRef<unknown>;

  constructor(
    public fb: UntypedFormBuilder,
    private translate: TranslateService,
    private subscriptionService: SubscribeService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sessionService: AdminSessionService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.subscribeForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.maxLength(60), validarEspacios, Validators.email]],
        fechaNacimiento: [null, []],
        condiciones: [false, Validators.requiredTrue],
        gamaHombre: [true],
        gamaMujer: [true],
        gamaNinyo: [false],
        relojes: [false],
        joyas: [false]
      },
      { validators: [this.minOneCheckedGamaValidator()] }
    );
  }

  minOneCheckedGamaValidator(): ValidatorFn {
    return (fg: UntypedFormGroup) => {
      return fg.get('gamaMujer').value === false && fg.get('gamaHombre').value === false && fg.get('gamaNinyo').value === false
        ? { minOneChecked: true }
        : null;
    };
  }

  minOneCheckedProductValidator(): ValidatorFn {
    return (fg: UntypedFormGroup) => {
      return fg.get('relojes').value === false && fg.get('joyas').value === false ? { minOneCheckedProduct: true } : null;
    };
  }

  isModal(): boolean {
    if (this.popUP) {
      return true;
    }
    return false;
  }

  onSubmit() {
    let msg: string;
    let alert: AlertType;
    this.subscribeForm.controls['fechaNacimiento'].clearValidators();
    this.subscribeForm.controls['fechaNacimiento'].updateValueAndValidity();
    this.submitted = true;
    if (this.subscribeForm.valid) {
      const data = {
        email: this.subscribeForm.controls.email.value,
        fechaNacimiento: this.subscribeForm.controls.fechaNacimiento.value,
        codigoIdioma: this.translate.currentLang,
        gamaMujer: this.subscribeForm.controls.gamaMujer.value,
        gamaHombre: this.subscribeForm.controls.gamaHombre.value,
        gamaNinyo: this.subscribeForm.controls.gamaNinyo.value,
        relojes: this.subscribeForm.controls.relojes.value,
        joyas: this.subscribeForm.controls.joyas.value
      } as ISuscripcionContacto;
      //subscribirse
      this.subscribe(data);
    } else {
      for (const name in this.subscribeForm.controls) {
        if (this.subscribeForm.controls[name].invalid) {
          msg = `commons.input.error.${name}`;
          alert = AlertType.WARNING;
          this.alertService.getInformationAlert(msg, alert);
          return;
        }
      }
      if (this.subscribeForm?.errors?.minOneChecked) {
        msg = 'commons.input.error.minOneChecked';
        alert = AlertType.WARNING;
        this.alertService.getInformationAlert(msg, alert);
      } else if (this.subscribeForm?.errors?.minOneCheckedProduct) {
        msg = 'commons.input.error.minOneCheckedProduct';
        alert = AlertType.WARNING;
        this.alertService.getInformationAlert(msg, alert);
      }
    }
  }

  subscribe(data) {
    let msg: string;
    let alert: AlertType;

    if (this.visibleButtonSubscribe) {
      //desactivar el boton de subscripcion
      this.toggleSubscribeButtonClicked();
      this.subscriptionService
        .doSubscribe(data)
        .toPromise()
        .then(response => {
          if (ValidarResponse.validar(response)) {
            if (this.isModal) {
              this.activeModal.close();
            }
            this.hideForm = true;
            this.visibleSubscribe = false;
            this.dataSubmitted.emit();
            this.openSubscribeModal();
            this.cerrar.emit(true);
            // Cabe la posibilidad que el cliente quiera introducir un email que no es el vinculada a la tienda
            // Cuando se vuelva a logar, le volverá a aparecer el cuadro de suscripción.
            if (this.sessionService.isClienteRegistrado()) {
              this.sessionService.setNewsletter(true);
            }
          } else if (response.code === 'SUS_ONL_ML_ERR') {
            msg = 'commons.input.error.email';
            alert = AlertType.WARNING;
            this.alertService.getInformationAlert(msg, alert);
          } else if (response.code === 'SUS_ONL_ML_N_USO') {
            msg = 'commons.input.error.emailEnUso';
            alert = AlertType.WARNING;
            this.alertService.getInformationAlert(msg, alert).then((resultado: SweetAlertResult) => {
              if (resultado.isConfirmed) {
                this.visibleSubscribe = false;
                this.cerrar.emit(true);
                this.cdr.markForCheck();
              }
            });
          }
          //activar el boton de subscripcion
          this.toggleSubscribeButtonClicked();
          this.cdr.markForCheck();
        });
    }
  }

  toggleSubscribeButtonClicked() {
    this.visibleButtonSubscribe = !this.visibleButtonSubscribe;
  }

  isVisibleButtonSubscribe() {
    return this.visibleButtonSubscribe;
  }

  esconderSubscribe() {
    this.visibleSubscribe = false;
  }

  public openSubscribeModal(): void {
    this.modalService.open(ModalThanksNewsletterComponent, { windowClass: 'successSubscribePopUp' });
  }

  getMonths() {
    const translatedMonths = [];
    MONTHS.forEach(month => {
      translatedMonths.push(this.translate.instant(`datos-personales.combo.fecha-nacimiento.${month}`));
    });
    return translatedMonths;
  }
}
