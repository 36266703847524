<div class="row suscribe-card" *ngIf="!isModal(); else subscribePopup">
  <div class="col-12 m-0 px-0" *ngIf="visibleSubscribe">
    <form [formGroup]="subscribeForm" role="form" id="subscribeForm">
      <div class="row">
        <div class="col-12 text-center header-4 text-letter-normal my-0">{{ 'subscribe.title' | translate }}</div>
        <div class="col-12 text-center subtitle-3 my-2-5 font-weight-normal">{{ 'subscribe.subtitle' | translate }}</div>
        <div class="col-12">
          <span class="paragraph-2 font-weight-bold">{{ 'subscribe.input.email' | translate }}</span>
        </div>
        <div class="col-12">
          <input
            type="text"
            id="email"
            formControlName="email"
            aria-label="Email"
            class="form-control pl-3 email p3 mx-auto my-2 paragraph-2 rounded"
            placeholder="{{ 'subscribe.placeholder' | translate }}"
          />
        </div>
        <div class="col-12 mb-3-5">
          <div class="form-check">
            <label for="condiciones" class="form-check-label">
              <input type="checkbox" formControlName="condiciones" id="condiciones" class="form-check-input paragraph-3" />
              <div class="form-check-sign gray-checkbox small">
                <span class="text-gray light-text p5 paragraph-3">
                  {{ 'subscribe.modal.acepto' | translate }}
                  <a [routerLink]="'/legal/condiciones' | localize" class="link-legal paragraph-3 font-weight-bold" rel="nofollow">
                    {{ 'subscribe.modal.terminos-condiciones' | translate }}
                  </a>
                </span>
              </div>
            </label>
          </div>
          <span class="form-check-sign"></span>
        </div>
        <div class="col-12 mb-4">
          <button [disabled]="!isVisibleButtonSubscribe()" (click)="onSubmit()" class="btn btn-info rounded-0 w-100 text-light bg-gris6">
            {{ 'subscribe.button' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Inicio Modal-->
<ng-template #subscribePopup>
  <div class="modal-body ng-star-inserted pt-0 pt-md-3" *ngIf="!hideForm">
    <form [formGroup]="subscribeForm" role="form" id="subscribeForm">
      <div class="row">
        <div class="col-12 text-center subtitle-3 mt-0 mb-0 my-md-3 my-lg-2">{{ 'subscribe.title' | translate }}</div>
        <div class="col-12 text-center subtitle-1 my-2">{{ 'subscribe.subtitle' | translate }}</div>
        <div class="col-12 mt-3 mt-md-5 mb-2">
          <span class="paragraph-2 font-weight-bold">{{ 'subscribe.input.email' | translate }}</span>
        </div>
        <div class="col-12">
          <input
            type="text"
            id="email"
            formControlName="email"
            aria-label="Email"
            class="form-control pl-3 email p3 mx-auto"
            placeholder="{{ 'subscribe.placeholder' | translate }}"
          />
        </div>
        <div class="col-12 mt-5 mb-1">
          <div class="form-check">
            <label for="condiciones" class="form-check-label">
              <input type="checkbox" formControlName="condiciones" id="condiciones" class="form-check-input" />
              <div class="form-check-sign gray-checkbox small">
                <span class="text-gray light-text p5 paragraph-4">
                  {{ 'subscribe.modal.acepto' | translate }}
                  <a [routerLink]="'/legal/condiciones' | localize" class="link-legal" rel="nofollow">
                    {{ 'subscribe.modal.terminos-condiciones' | translate }}
                  </a>
                </span>
              </div>
            </label>
          </div>
          <span class="form-check-sign"></span>
        </div>
        <div class="col-12 mb-4">
          <button [disabled]="!isVisibleButtonSubscribe()" (click)="onSubmit()" class="btn btn-info rounded-0 w-100 text-light">
            {{ 'subscribe.button' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <!-- </div>-->
</ng-template>

<ng-template #modalSuccess>
  <div class="modal-header border-0 pb-0">
    <button type="button" class="close" aria-label="Close" (click)="closeModalSucces()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row pt-2">
        <span class="subtitle-8 text-center subtitle-3">{{ 'subscribe.titulo.correcto' | translate }}</span>
      </div>
      <div class="row py-2 justify-content-center subtitle-3" style="padding-top: 2.5rem !important; padding-bottom: 3.5rem !important">
        <span class="text-center">{{ 'subscribe.titulo.correcto2' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
