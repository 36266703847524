<div class="modal-content">
  <div class="modal-header border-0 pb-0">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row pt-2">
        <span class="subtitle-1 text-center">{{ 'subscribe.titulo.correcto' | translate }}</span>
      </div>
      <div class="row py-2 justify-content-center" style="padding-top: 2.5rem !important; padding-bottom: 3.5rem !important">
        <span class="text-center paragraph-1">{{ 'subscribe.titulo.correcto2' | translate }}</span>
      </div>
    </div>
  </div>
</div>
