import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fgp-modal-thanks-newsletter',
    templateUrl: './modal-thanks-newsletter.component.html',
    styleUrls: ['./modal-thanks-newsletter.component.scss'],
    standalone: false
})
export class ModalThanksNewsletterComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
