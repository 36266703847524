import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FeatureComboDatePickerModule } from '@fgp/components/combo-datepicker/feature-combo-datepicker';
import { SubscribeUiModalThanksNewsletterModule } from '@fgp/subscribe/ui/modal-thanks-newsletter';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LocalizeRouterModule,
    ReactiveFormsModule,
    RouterModule,
    FeatureComboDatePickerModule,
    SubscribeUiModalThanksNewsletterModule
  ],
  exports: [CommonModule, TranslateModule, LocalizeRouterModule, ReactiveFormsModule, RouterModule, FeatureComboDatePickerModule]
})
export class SubscribeFeatureSubscribeBaseModule {}
