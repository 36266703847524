import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SubscribeBaseDirective } from '@fgp/subscribe/feature-subscribe/base';

@Component({
    selector: 'fgp-subscribe-perrelet',
    templateUrl: './subscribe-perrelet.component.html',
    styleUrls: ['./subscribe-perrelet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubscribePerreletComponent extends SubscribeBaseDirective {}
