import { NgModule } from '@angular/core';
import { SubscribeFeatureSubscribeBaseModule } from '@fgp/subscribe/feature-subscribe/base';
import { SubscribePerreletComponent } from './subscribe-perrelet/subscribe-perrelet.component';

@NgModule({
  imports: [SubscribeFeatureSubscribeBaseModule],
  declarations: [SubscribePerreletComponent],
  exports: [SubscribePerreletComponent]
})
export class SubscribeFeatureSubscribePerreletModule {}
